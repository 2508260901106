import { isValidEmbedUrl } from './validate';

import { LOOM_HOSTNAME_CAPTURE } from './common';

const SRC_URL_REGEX = /src=["']+(https?:\/\/[a-zA-z\d:\.\/?&]+)/;

const getResponsiveEmbedCode = (
  embedURL: string,
  heightAspectRatio: number
): string => {
  const padding = `${heightAspectRatio * 100}%`;
  const wrapperStyles = `position: relative; padding-bottom: ${padding}; height: 0;`;
  const iframeStyles =
    'position: absolute; top: 0; left: 0; width: 100%; height: 100%;';
  const staticAttributes =
    'frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen';

  return `
<div class="lo-emb-vid" style="${wrapperStyles}">
  <iframe src="${embedURL}" style="${iframeStyles}" ${staticAttributes}></iframe>
</div>`
    .split('\n')
    .map(s => s.trim())
    .join('');
};

const chooseAspectRatio = (data: OEmbedInterface) => {
  const { width, height } = data;

  // Fallback to 16:9 ratio when width or height is null
  if (width == null || height == null) {
    return 1080 / 1920;
  }

  return height / width;
};

const enhanceEmbedCode = (
  videoData: OEmbedInterface,
  options: Option
): OEmbedInterface => {
  // If needed, we alter the embed code to
  // support responsive code
  const { width, height } = options;

  if (!width && !height) {
    const [, embedUrl] = <RegExpMatchArray>videoData.html.match(SRC_URL_REGEX);
    const aspectRatio = chooseAspectRatio(videoData);

    return {
      ...videoData,
      html: getResponsiveEmbedCode(embedUrl, aspectRatio),
    };
  }

  return videoData;
};

interface Option {
  width?: number;
  height?: number;
}

interface OEmbedInterface {
  type: 'video';
  html: string;
  title: string;
  height: number | null;
  width: number | null;
  provider_name: 'Loom';
  provider_url: string;
  thumbnail_height: number;
  thumbnail_width: number;
  thumbnail_url: string;
  duration: number;
}

const buildLoomOembedUrl = (url: string, options: Option): string => {
  const { width, height } = options;
  const maxWidth = width ? `&maxwidth=${width}` : '';
  const maxHeight = height ? `&maxheight=${height}` : '';

  const [loomDomain] = <RegExpMatchArray>url.match(LOOM_HOSTNAME_CAPTURE);

  let loomBaseDomain = loomDomain;

  if (loomDomain === 'loom.com') {
    loomBaseDomain = `www.${loomDomain}`;
  }

  return `https://${loomBaseDomain}/v1/oembed?url=${url}${maxWidth}${maxHeight}`;
};

const oembed = (
  linkUrl: string,
  options: Option = {}
): Promise<OEmbedInterface> => {
  const isSupportedUrl = isValidEmbedUrl(linkUrl);

  if (isSupportedUrl) {
    return fetch(buildLoomOembedUrl(linkUrl, options))
      .then(resp => resp.json())
      .then(videoData => enhanceEmbedCode(videoData, options))
      .catch(e => {
        throw new Error('Unable to fetch oembed data:' + e);
      });
  }

  throw new Error('URL is not from a supported video provider');
};

export { oembed };
export type { OEmbedInterface, Option };
